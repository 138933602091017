export default defineNuxtRouteMiddleware((_to, _from) => {
  const { setAppView } = useAuthStore();

  const requestUserAgent = useRequestHeader('user-agent');

  const userAgent = import.meta.server ? requestUserAgent : navigator?.userAgent;

  if (userAgent?.includes('PCL_')) {
    setAppView();
  }
});
